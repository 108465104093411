.hero {
	position: relative;
	&__image {
		width: 100%;
		height: 332px;
		background-size: cover;
		background-position: center;
		margin: 80px 0 0 0;
		@media screen and ($tablet) {
			height: 824px;
			margin: 100px 0 0 0;
		}
		@media screen and ($desktop-big){
			background-attachment: fixed;
		}
	}
	&__partner {
		position: absolute;
		bottom: 10px;
		left: 20px;
		list-style: none;
		@media screen and ($tablet) {
			display: flex;
		}
		&__comma {
			color: $second-color;
		}
		&__name {
			margin: 0 5px 0;
			font-size: 20px;
			line-height: 38px;
			opacity: 0;
			display: none;
			transition: all 0.5s;
			&:last-child {
				.hero__partner__comma {
					display: none;
				}
			}
		}
		&__url {
			color: $second-color;
			&:hover {
				color: $ninth-color;
			}
		}
		&.show {
			bottom: 20px;
			.hero__partner__name {
				opacity: 1;
				display: block;
			}
		}
	}
	&__layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		transition: all 1s;
		&.show {
			width: 100%;
			background: linear-gradient(
				0deg,
				rgba(43, 43, 43, 0.25),
				rgba(43, 43, 43, 0.25)
			);
		}
	}
	&__toggle {
		position: absolute;
		left: 20px;
		bottom: 20px;
		transition: transform 0.5s;
		transform: rotate(0deg);
		overflow: hidden;
		height: 60px;
		width: 60px;
		svg {
			position: absolute;
			left: -5px;
			top: 0;
		}
		@media screen and ($tablet) {
			cursor: pointer;
			display: block;
		}
	}
}
