.introAnime {
	background: $third-color;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	&__logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 200px;
		@media screen and ($tablet) {
			max-width: 250px;
		}
	}
	&__slide001 {
		background: #3b81c3;
		position: absolute;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
	}
	&__slide002 {
		background: $fourth-color;
		position: absolute;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
	}
}
