.contactdoor {
  &__wrap {
    @media screen and ($tablet) {
      display: flex;
    }
  }
  &__reverse {
    @media screen and ($tablet) {
      flex-direction: row-reverse;
    }
  }
  &__image,
  &__text {
    min-height: 333px;
    @media screen and ($tablet) {
      width: 50%;
      min-height: 422px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    padding: 100px 25px;
    opacity: 0;
    transition: opacity 0.5s;
    transition-delay: 0.5s;
    @media screen and ($desktop) {
      flex-direction: row;
      padding: 100px 50px;
    }
    @media screen and ($desktop-big) {
      padding: 200px 100px;
    }
    &__box {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 1.5em;
      a {
        color: $main-color;
      }
      @media screen and ($desktop) {
        &:nth-child(1) {
          margin: 0 30px auto auto;
        }
        &:nth-child(2) {
          margin: 0 auto auto 30px;
        }
      }
      @media screen and ($desktop-big) {
        &:nth-child(1) {
          margin: 0 70px auto auto;
        }
        &:nth-child(2) {
          margin: 0 auto auto 70px;
        }
      }
    }
    &.gray {
      background: $fifth-color;
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    &-inner {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: -100px;
      background: {
        size: cover;
        position: 0 50%;
        color: $soft-blue;
      }
      backface-visibility: none;

      &:before {
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: $eleventh-color;
        display: block;
        position: absolute;
        z-index: 2;
        transition-property: width;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
      }
    }
  }
  &.show {
    .contactdoor__image {
      &-inner {
        transform: scale(1);
        &:before {
          width: 0;
          transition-property: width;
          transition-duration: 0.8s;
          transition-delay: 0.1s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .contactdoor__text {
      opacity: 1;
    }
  }
  &__title {
    color: $main-color;
    line-height: 44px;
    font-size: 26px;
    min-height: 38px;
    @media screen and ($tablet) {
      line-height: 38px;
      font-size: 20px;
    }
  }
  &__contact {
    color: $eight-color;
  }
  &__block-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &__content {
    margin: 0 0 auto;
    color: $eight-color;
    margin: 0 0 30px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media screen and ($tablet) {
      margin: 0 0 40px;
      width: 50%;
      justify-content: flex-start;
    }
  }
}
