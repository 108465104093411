.lightboxvideo {
	z-index: 12;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(51, 51, 51, 0.92);
	visibility: hidden;
	opacity: 0;
	transition-delay: 2s;
	transition: opacity 1s;
	&.show {
		visibility: visible;
		opacity: 1;
	}
}

.lightboxvideo__video-container {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 1200px;
	padding: 0 20px;
	&:hover {
		.lightboxvideo__close {
			opacity: 1;
			visibility: visible;
		}
		.lightboxvideo__video-toggle--play {
			opacity: 1;
			visibility: visible;
		}
	}
}

.lightboxvideo__video {
	width: 100%;
	object-fit: cover;
}

.lightboxvideo__video-toggle {
	width: 100px;
	height: 100px;
	border: 3px solid white;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: white;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	cursor: pointer;
	font-family: $font-cooper;
	transition: 300ms ease-in-out all;
	&.videostate {
		.lightboxvideo__video-toggle--pausetext {
			display: block;
		}
		.lightboxvideo__video-toggle--playtext {
			display: none;
		}
	}
}

.lightboxvideo__video-toggle--pausetext {
	display: none;
}

.lightboxvideo__openvideo {
	cursor: pointer;
	transition: all 0.5s;
	color: $main-color;
	&:hover {
		color: $ninth-color;
	}
}

.lightboxvideo__close {
	opacity: 0;
	transition: opacity 0.5s;
	position: absolute;
	top: 5px;
	right: 25px;
	width: 30px;
	height: 30px;
	transform: rotate(45deg);
	z-index: 2;
	cursor: pointer;
	.lightboxvideo__line {
		background: $second-color;
		position: absolute;
		&:nth-child(1) {
			width: 100%;
			height: 2px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		&:nth-child(2) {
			width: 2px;
			height: 100%;
			transform: translateX(-50%);
			left: 50%;
			top: 0;
		}
	}
}
