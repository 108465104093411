.statistics {
  background: $eleventh-color;
  padding: 80px 30px;
  &__wrap {
    max-width: 900px;
    margin: auto;
    @media screen and ($tablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__title {
    margin: 0 0 80px;
    line-height: 44px;
    font-size: 26px;
    text-align: center;
    color: $main-color;
    @media screen and ($tablet) {
      width: 100%;
      line-height: 52px;
      font-size: 30px;
    }
  }
  &__numbers {
    color: $main-color;
    text-align: center;
    @media screen and ($tablet) {
      width: calc(33.333% - 20px);
      &:nth-child(3) {
        border-left: solid 1px $second-color;
        border-right: solid 1px $second-color;
      }
    }
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }
  &__number {
    backface-visibility: hidden;
    line-height: 54px;
    font-size: 48px;
    @media screen and ($tablet) {
      line-height: 64px;
      font-size: 54px;
    }
    @media screen and ($desktop) {
      line-height: 74px;
      font-size: 64px;
    }
    @media screen and ($desktop-big) {
      line-height: 94px;
      font-size: 80px;
    }
  }
  &__text {
    line-height: 38px;
    margin-top: 20px;
    font-size: 20px;
  }
}
