.main_header {
  height: 100vh;
  background-color: $main-color;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;

  &.main_header__port {
    margin: 0 0 80px;
    @media screen and ($tablet) {
      margin: 0;
    }
  }

  &--has-video {
    .main_header__layer {
      display: none;
    }
    .main_header__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: none;
    }
    .main_header__video-preview {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 120%;
      min-height: calc(100vw - 500px);
      z-index: 0;
      object-fit: cover;
      width: 100%;
      @media all and (max-width: 767px) {
        min-height: auto;
        height: 110%;
        width: 125%;
      }
    }

    .main_header__video-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: black;
      opacity: 0.5;
      transition: 300ms ease-in-out all;
      overflow: hidden;

      &.play-state {
        opacity: 1;
        transition: 300ms ease-in-out all;
        z-index: 1;
      }
    }
    .main_header__video-button {
      border: 2px solid white;
      background: transparent;
      color: white;
      display: block;
      margin: 0 auto;
      padding: 20px 40px;
      font-size: 20px;
      font-family: "CooperLtBT", serif;
      margin-top: 30px;
      transition: 300ms ease-in-out all;
      cursor: pointer;
      &:active {
        background-color: white;
        color: black;
        transition: 300ms ease-in-out all;
      }
      @media all and ($desktop) {
        margin-top: 80px;
        &:hover {
          background-color: white;
          color: black;
          transition: 300ms ease-in-out all;
        }
      }
    }
    .main_header__layer {
      background: rgba(0, 0, 0, 0.6);
    }
    .main_header__wrap {
      svg path {
        fill: #ffffff;
      }
      .main_header__logoholder__extended {
        color: white;
      }
    }
    .main_header__wrap:not(.fix) {
      .main_header__menu {
        .main_header__menu__text {
          color: white;
        }
        .hamburger-box {
          .hamburger-inner {
            background-color: white;
            &:after,
            &:before {
              background-color: white;
            }
          }
        }
      }
    }

    .main_header__video-toggle {
      width: 100px;
      height: 100px;
      border: 3px solid white;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      cursor: pointer;
      font-family: "CooperLtBT", serif;
      transition: 300ms ease-in-out all;
    }

    &.video-playing {
      .main_header__video {
        display: block;
      }
      .main_header__wrap .wrap,
      .main_header__title-container,
      .main_header__emblem {
        margin-top: 30px;
        opacity: 0;
        visibility: hidden;
        transition: 500ms ease-in-out all;
      }

      &:after {
        opacity: 1;
        visibility: visible;
        transition: 500ms ease-in-out all;
      }

      .main_header__video {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.5s;
        transition: 500ms ease-in-out all;
      }
    }
    &.paused {
      .main_header__video {
        opacity: 0.5;
        transition: 300ms ease-in-out all;
      }
      .main_header__video-toggle--pause {
        visibility: visible;
        opacity: 1;
        transition: 300ms ease-in-out all;
      }
      .main_header__wrap {
        z-index: 20;
      }
      .main_header__wrap .wrap,
      .goto {
        margin-top: 0;
        opacity: 1;
        visibility: visible;
        transition: 500ms ease-in-out all;
        z-index: 6;
      }
    }
    &:not(.paused) {
      &.pause-hover {
        .main_header__video {
          opacity: 0.5;
        }
        .main_header__video-toggle--play {
          visibility: visible;
          opacity: 1;
          transition: 300ms ease-in-out all;
        }
      }
    }
  }

  &__wrap {
    margin: 40px auto 0 auto;
    width: 100%;
    z-index: 2;
    transition: 200ms ease-in-out width;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    @include keyframes(fade-menu, 0.4s, 0.1s, ((opacity, 0, 1), (opacity, 0, 1)));
    &:not(.fix) {
      .main_header__menu--white {
        .main_header__menu__text {
          color: white;
        }
        .hamburger {
          .hamburger-inner {
            background-color: white;
            &:before,
            &:after {
              background-color: white;
            }
          }
        }
      }
    }

    &.fix {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
      transition: 200ms ease-in-out width;
      @include keyframes(
        animate-fix-menu,
        0.4s,
        0.1s,
        ((opacity, 0, 1), (transform, translateY(-100%), translateY(0)))
      );
      .main_header__menu__text.white {
        color: $sixth-color;
      }
      .main_header__sandwich__bar.white {
        background-color: $sixth-color;
      }
      .main_header__logo {
        path {
          fill: $sixth-color;
        }
      }
      .main_header__logoholder__extended {
        span {
          color: $sixth-color;
        }
        svg {
          path {
            fill: $sixth-color;
          }
        }
      }
      &.mobile-format {
        > .wrap {
          padding: 20px 0;
        }
      }
      > .wrap {
        padding: 40px 0;
      }
    }
    .hamburger:focus {
      outline: 0;
    }

    &.open {
      z-index: 100;

      .main_header__logoholder {
        svg path {
          fill: white;
        }
      }
      .main_header__menu__text {
        color: white;
      }
      .hamburger-inner {
        background-color: white;
        &:before,
        &:after {
          background-color: white;
        }
      }

      &.fix {
        background-color: transparent;
        box-shadow: none;
      }
      .main_header__logoholder__extended {
        span {
          color: $second-color;
        }
      }
    }
    > .wrap > .wrap-inside {
      display: flex;
      justify-content: space-between;
    }
  }
  &__logo {
    display: block;
  }
  &__menu {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    &__text {
      color: $sixth-color;
      font-size: 18px;
      margin: auto 0;
      &.white {
        color: $second-color;
      }
    }
  }
  &__sandwich {
    width: 30px;
    height: 20px;
    background: transparent;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto 0;
    &:focus {
      outline: none;
    }
    &__bar {
      background: $sixth-color;
      height: 3px;
      width: 30px;
      cursor: pointer;
      border-radius: 50px;
      &.white {
        background: $second-color;
      }
    }
  }
  &__nav {
    padding: 170px 32px 72px 32px;
    background: $fourth-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;

    @media screen and ($desktop-small) {
      padding: 220px 32px;
    }

    .main_header__menu__text {
      color: $second-color;
    }
    .main_header__sandwich__bar {
      background: $second-color;
    }
    .main_header__sandwich {
      position: relative;
      top: 0;
      background: $fourth-color;
    }
    &.open {
      z-index: 11;
      opacity: 1;
    }
    &__menu {
      font-size: 26px;
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: flex;
      .main-menu {
        display: block;
        line-height: 1em;
        &:first-child .main-menu__title {
          margin-top: unset;
        }
        .main-menu__title {
          display: block;
          margin-top: 3em;
          margin-bottom: 8px;
          &.is-link:hover {
            border-bottom: solid 2px $blue;
            margin-bottom: 6px;
          }
        }
      }
      .main-menu__link {
        color: $white;
        display: block;
        padding: 8px 16px;
        margin-left: -16px;
        &.back {
          display: flex;
          width: max-content;
          font-size: 16px;
          padding-right: 16px;
          line-height: 28px;
        }
        svg {
          margin-top: 6px;
          margin-right: 16px;
        }
      }
    }
    &__origin {
      flex: 1 0 100%;
      display: inline-block;
    }
    &__submenu {
      flex: 1 0 100%;
      @media screen and ($tablet) {
        transition: opacity ease-in-out 0.2s;
        &.hidden {
          pointer-events: none;
          opacity: 0;
        }
      }
    }
    &__submenu-scroll {
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: inline-flex;
      @media screen and ($tablet) {
        height: unset;
      }
      .main_header__nav__submenu__item {
        padding-top: 8px;
        flex: 1 0 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .main-menu__spacer {
        flex: 1;
      }
      .main-menu__title {
        color: $white;
      }
      .main-menu__link {
        font-size: 20px;
      }
    }

    @media screen and ($desktop-small) {
      &__menu {
        padding: 0 60px;
        font-size: 24px;
        @media screen and ($desktop) {
          font-size: 28px;
          .main-menu__link {
            padding: 14px 16px;
          }
        }
        @media screen and ($desktop-big) {
          font-size: 32px;
          .main-menu__link {
            padding: 16px 16px;
          }
        }
        @media screen and (min-width: 1440px) {
          font-size: 36px;
          .main-menu__link {
            padding: 20px 16px;
          }
        }
        display: flex;
        overflow: unset;
        .back {
          //hides the back button on desktop
          display: none !important;
        }
        .main-menu {
          margin-left: -16px;
          width: max-content;
          display: block;
          .main-menu__title {
            padding: 8px 0;
          }
          .main-menu__link {
            display: block;
            &.soft {
              color: $font-slate;
            }
            &:hover,
            &.active {
              color: white;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                bottom: 12px;
                left: 16px;
                width: calc(100% - 32px);
                height: 0;
                border-top: solid 2px $blue;
              }
            }
          }
        }
      }
      &__origin {
        flex: 1 0 350px;
      }
      &__submenu {
        position: relative;
        flex: 4;
        &:after {
          content: "";
          position: absolute;
          height: 200vh;
          width: 1px;
          left: 0;
          top: -100vh;
          background: $font-slate;
        }
      }
      &__submenu-scroll {
        overflow: hidden;

        .main-menu {
          margin-left: 98px;
          color: $white;
        }
        .main-menu__link {
          font-size: 28px;
          color: $font-slate;
        }

        &__item {
          height: 100%;
        }
      }
    }

    &__language-switch {
      position: absolute;
      right: 40px;
      bottom: 70px;

      @media screen and ($desktop) {
        right: 140px;
        bottom: 140px;
      }
    }
    .main_header__logoholder {
      .main_header__logoholder__extended {
        span {
          color: $second-color;
        }
        svg {
          path {
            fill: $second-color;
          }
        }
      }
    }
  }
  &__title-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
  }
  &__video-button {
    border: 2px solid white;
    background: transparent;
    color: white;
    display: block;
    margin: 0 auto;
    padding: 20px 40px;
    font-size: 20px;
    font-family: "CooperLtBT", serif;
    margin-top: 40px;
    transition: 300ms ease-in-out all;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: black;
      transition: 300ms ease-in-out all;
    }
  }
  &__title {
    position: relative;
    color: $second-color;
    margin: auto;
    text-align: center;
    font-size: 26px;
    line-height: 44px;
    max-width: 1200px;
    align-self: center;
    padding: 0 32px;
    @include keyframes(animate-title, 0.4s, 0.8s, ((opacity, 0, 1), (transform, translateY(-50px), translateY(0))));

    &__subtitle {
      font-size: 20px;
      display: block;
      @media screen and ($tablet) {
        font-size: 40px;
      }
    }
    &__left {
      text-align: left;
      margin: auto auto 20px 0;
      padding: 0;
      @media screen and ($tablet) {
        max-width: 550px;
        margin: auto auto 40px 0;
      }
      @media screen and ($desktop) {
        margin: auto auto 40px 95px;
      }
    }
    @media screen and ($tablet) {
      font-size: 40px;
      line-height: 64px;
    }
    @media screen and ($desktop-big) {
      font-size: 54px;
      line-height: 84px;
    }
    br {
      display: none;
      @media screen and ($tablet) {
        display: inline;
      }
    }
  }
  &__subtitle {
    position: relative;
    color: $second-color;
    line-height: 32px;
    font-size: 18px;
    font-weight: normal;
    @include keyframes(animate-title, 0.4s, 0.9s, ((opacity, 0, 1), (transform, translateY(-50px), translateY(0))));
    @media screen and ($tablet) {
      margin: 0 auto auto 0;
      line-height: 40px;
      font-size: 24px;
    }
    &__left {
      @media screen and ($desktop) {
        margin: 0 auto auto 95px;
      }
    }
  }
  &__emblem {
    position: absolute;
    bottom: 0;
    left: calc(50% + 4px);
    display: none;
    height: 100%;
    width: auto;
    @include keyframes(
      emblem-header,
      0.9s,
      1s,
      ((opacity, 0, 1), (transform, translate(-50%, 300px), translate(-50%, 0)))
    );
    @media screen and ($tablet) {
      display: block;
    }
  }
  &__text {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    background: $main-color;
    padding: 40px 30px;
    @media screen and ($desktop-small) {
      z-index: 1;
      bottom: auto;
      left: 0;
    }
  }
  &__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 1;
    @media screen and ($desktop-small) {
      right: 0;
      left: auto;
    }
  }
  &__text,
  &__image {
    position: absolute;
    display: flex;
    flex-direction: column;
    @media screen and ($desktop-small) {
      height: 100%;
      width: 50%;
    }
  }
  &__pin {
    position: absolute;
    left: 50%;
    bottom: -68px;
    transform: translateX(-50%);
    width: 2px;
    height: 140px;
    background: $main-color;
    @media screen and ($tablet) {
      bottom: -140px;
      height: 280px;
    }
  }
  &__port {
    + .pad {
      @media screen and ($tablet) {
        padding: 90px 0;
      }
    }
  }
  &__layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $main-color;
    opacity: 0.9;
    &--black {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.456) 0.68%, rgba(0, 0, 0, 0.384) 92.37%);
    }
  }

  &__logoholder {
    display: flex;
    align-items: center;
    &__extended {
      span {
        margin: 0 36px;
      }
      display: none;
      @media screen and ($tablet) {
        display: block;
      }
    }
  }
  .anchor {
    position: absolute;
    bottom: 108px;
    left: 0;
    width: 100%;
  }
  .goto {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 160px;
    cursor: pointer;
    &.active {
      .goto__arrow {
        opacity: 1;
      }
      .active-hide {
        opacity: 0;
      }
    }
    &__container {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &__arrow {
      @media screen and ($tablet) {
        opacity: 0;
      }
      $arrowSize: 32px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 56px;
      width: $arrowSize;
      height: $arrowSize;
      margin: 0 auto;
      animation-name: floating-arrow;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
  @keyframes floating-arrow {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(18px);
    }
  }
  .hamburger {
    padding-top: 20px;
    margin-right: -20px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #2d3856;
    width: 30px;
    border-radius: 10px;
  }
  &.news {
    .main_header__date {
      font-size: 24px;
      line-height: 40px;
      color: $second-color;
      margin: auto auto 10px 0;
      @include keyframes(animate-title, 0.4s, 0.9s, ((opacity, 0, 1), (transform, translateY(-50px), translateY(0))));
      @media screen and ($tablet) {
        padding: 0;
      }
    }
    .main_header__title {
      margin: 0 auto auto 0;
    }
  }
}
