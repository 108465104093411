.values {
  &__intro {
    padding: 0 15px;
    margin: 127px auto 103px;
    @media screen and ($tablet) {
      width: 720px;
    }
    &__title {
      color: $main-color;
      text-align: center;
      font-family: $font-cooper;
      h2,
      h3 {
        font-weight: normal;
      }
      p {
        line-height: 38px;
        font-size: 20px;
      }
      @media screen and ($tablet) {
        p {
          line-height: 38px;
          font-size: 20px;
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }
  &__cta {
    margin-top: 18px;
    font-family: $font-karla;
    font-size: 16px;
    line-height: 32px;
    color: #3b81c3;
    @media screen and ($tablet) {
      margin-top: 50px;
    }

    svg {
      margin-left: 12px;
      transition: transform 0.2s ease-in-out;
    }
    &:hover {
      svg {
        transform: translate(12px);
      }
    }
  }
  &__box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 2px solid $fifth-color;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.03);
    margin: 0 0 40px;
    @media screen and ($tablet) {
      width: calc(33.333% - 38px);
      padding: 32px 38px;
      padding-bottom: 28px;
    }
    &--has-hover {
      font-family: $font-karla;
      background: none;
      text-align: left;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      &:hover {
        .values__hover {
          opacity: 1;
        }
      }

      @media screen and (max-width: 768px) {
        &.in-focus .values__hover {
          opacity: 1;
        }
      }
    }
  }
  &__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
  }
  &__hover-content {
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and ($tablet) {
      padding: 32px 38px;
      padding-bottom: 28px;
    }
    .values__cta {
      color: $white;
      z-index: 1;
    }
    p {
      font-family: $font-cooper;
      font-size: 20px;
      font-weight: 400;
      line-height: 38px;
      color: $white;
      z-index: 1;
      position: relative;
      flex: 1;
    }

    img,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 0;
    }
    &:after {
      content: "";
      opacity: 0.8;
      background-color: $blue;
    }
  }
  &__title {
    line-height: 38px;
    font-size: 20px;
    color: $main-color;
    margin: 0 0 9px;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    @media screen and ($tablet) {
      padding: 0 10px 120px 10px;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1155px;
      width: 100%;
      margin: 0 auto;
      border-bottom: solid 1px $fifth-color;
    }
  }
  &__content {
    flex: 1;
    color: $eight-color;
  }
  a {
    transition: all 0.5s;
    color: $main-color;
    &:hover {
      color: $ninth-color;
    }
  }
}
