.usp {
  @media screen and ($tablet) {
    display: flex;
  }
  &__wrap {
    position: relative;
    background: $main-color;
    display: flex;
    flex-direction: column;
    height: 100vw;
    height: 100vw;
    padding: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and ($tablet) {
      width: 33.333vw;
      max-width: calc(1920px / 3);
      max-height: calc(1920px / 3);
      height: 33.333vw;
    }
    &:nth-child(1) {
      .usp__layer {
        background: linear-gradient(
          0deg,
          rgba(216, 236, 255, 0.9),
          rgba(216, 236, 255, 0.9)
        );
      }
      .usp__content,
      .usp__title {
        color: $sixth-color;
      }
    }
    &:nth-child(2) {
      .usp__layer {
        background: linear-gradient(
          0deg,
          rgba(59, 129, 195, 0.9),
          rgba(59, 129, 195, 0.9)
        );
      }
    }
    &:nth-child(3) {
      .usp__layer {
        background: linear-gradient(
          0deg,
          rgba(45, 56, 86, 0.9),
          rgba(45, 56, 86, 0.9)
        );
      }
    }
  }
  &__layer {
    background: $main-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__title {
    position: relative;
    margin: auto;
    color: $second-color;
    text-align: center;
    font-size: 30px;
    opacity: 1;
    line-height: 44px;
    font-size: 26px;
    max-width: 260px;
    width: 100%;
    @media screen and ($tablet) {
      line-height: 52px;
      font-size: 30px;
    }
  }
  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
    opacity: 0;
    padding: 0 5%;
    text-align: center;
    color: $second-color;

    @media screen and ($tablet) {
      line-height: 24px;
      font-size: 1.3vw;
      padding: 0 20px;
    }

    @media screen and ($desktop) {
      line-height: 28px;
      font-size: 16px;
      padding: 0 40px;
    }
    @media screen and ($desktop-big) {
      line-height: 32px;
      font-size: 18px;
      padding: 0 60px;
    }
  }
}

.uspAnime {
  .usp__wrap {
    transition: all 0.5s;
    opacity: 0;
    position: relative;
    top: 20px;
  }
  .usp__title {
    position: relative;
    transition: all 0.6s;
    opacity: 0;
    top: -40px;
  }
  .usp__content {
    transition: all 0.6s;
    opacity: 0;
  }
  &.show {
    .usp__title,
    .usp__wrap {
      opacity: 1;
      top: 0;
    }
  }
  .uspHover {
    .usp__title {
      opacity: 0;
      top: 20px;
    }
    .usp__content {
      transition-delay: 0.6s;
      opacity: 1;
    }
  }
}

.uspHover {
  .usp__title {
    opacity: 0;
    top: 20px;
  }
  .usp__content {
    transition-delay: 0.6s;
    opacity: 1;
  }
}
