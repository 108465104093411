@font-face {
  font-family: "CooperLtBT";
  src: url("/assets/fonts/CooperBT-Light.eot");
  src: url("/assets/fonts/CooperBT-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/CooperBT-Light.woff") format("woff"),
    url("/assets/fonts/CooperBT-Light.ttf") format("truetype"),
    url("/assets/fonts/CooperBT-Light.svg#CooperBT-Light") format("svg");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Karla";
  src: url("/assets/fonts/Karla-Bold.eot");
  src: url("/assets/fonts/Karla-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Karla-Bold.woff") format("woff"),
    url("/assets/fonts/Karla-Bold.ttf") format("truetype"),
    url("/assets/fonts/Karla-Bold.svg#Karla-Bold") format("svg");
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Karla";
  src: url("/assets/fonts/Karla-BoldItalic.eot");
  src: url("/assets/fonts/Karla-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Karla-BoldItalic.woff") format("woff"),
    url("/assets/fonts/Karla-BoldItalic.ttf") format("truetype"),
    url("/assets/fonts/Karla-BoldItalic.svg#Karla-BoldItalic") format("svg");
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Karla";
  src: url("/assets/fonts/Karla-Italic.eot");
  src: url("/assets/fonts/Karla-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Karla-Italic.woff") format("woff"),
    url("/assets/fonts/Karla-Italic.ttf") format("truetype"),
    url("/assets/fonts/Karla-Italic.svg#Karla-Italic") format("svg");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Karla";
  src: url("/assets/fonts/Karla-Regular.eot");
  src: url("/assets/fonts/Karla-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Karla-Regular.woff") format("woff"),
    url("/assets/fonts/Karla-Regular.ttf") format("truetype"),
    url("/assets/fonts/Karla-Regular.svg#Karla-Regular") format("svg");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: geometricPrecision;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

body {
  font-family: $font-karla;
  font-size: 17px;
  background: #e5e5e5;

  &.no-scroll {
    height: 100%;
    overflow: hidden;
  }
}

a {
  color: #111;
  text-decoration: none;
}

p {
  font-size: 18px;
  line-height: 32px;
  @media screen and ($tablet) {
    font-size: 16px;
    line-height: 26px;
  }
  @media screen and ($desktop-big) {
    font-size: 18px;
    line-height: 32px;
  }
}

ul,
ol {
  margin: 0 0 10px 20px;
  text-align: left;
  font-size: 18px;
  line-height: 32px;
  @media screen and ($tablet) {
    font-size: 16px;
    line-height: 26px;
  }
  @media screen and ($desktop-big) {
    font-size: 18px;
    line-height: 32px;
  }
}

h2 {
  line-height: 44px;
  font-size: 26px;
}

.title {
  font-family: $font-cooper;
  font-weight: normal;
}

.wrap {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  
  &.white {
    background: $second-color;
  }
}

.wrap-inside {
  margin: 0 auto;
  max-width: calc(100% - #{30px * 2});
  @media screen and ($tablet) {
    max-width: calc(100% - #{60px * 2});
  }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 1200px;
  overflow-x: hidden;
}
