// video.twig
.video-text {
  &__wrap {
    flex-direction: column-reverse;
    display: flex;
    @media screen and ($desktop) {
      flex-direction: row;
      &--left {
        flex-direction: row-reverse;
      }
    }
  }
  &__container {
    flex: 5;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__content {
    padding: 42px 24px;
    @media screen and ($desktop) {
      padding: 42px 114px;
    }
  }
  &__title {
    font-family: $font-cooper;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
    color: $blue;
  }
  &__text {
    font-family: Karla;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: $font-gray;
  }
  &__video {
    flex: 7;
  }
}

// VideoPlaceholder.vue
.video-placeholder {
  height: 100%;
  &__image {
    position: relative;
    height: 100%;
    padding-top: 100% * 9/16;
    img,
    video {
      background-color: $blue;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: cover;
    }
    video {
      z-index: 1;
    }
  }
  &__open-btn {
    position: absolute;
    background: none;
    border: none;
    border-radius: 100%;
    padding: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    &:hover {
      background: transparentize($color: $blue, $amount: 0.9);
      svg {
        transform: scale(1.05);
      }
    }
  }
}

// VideoPlaceholder.vue (v-if)
.video-modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: transparentize(#000, $amount: 0.33);
  &__wrap {
    padding-top: 140px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
  }
  &__container {
    margin: 0 auto;
    display: block;
    position: relative;
    max-height: calc(100vh - 282px);
    max-width: calc(100% - 64px);
    width: max-content;
    @media screen and ($desktop) {
      max-width: 60%;
    }
  }
  &__close-btn {
    margin-top: 48px;
    font-size: 18px;
    padding: 12px 24px;
    min-width: 164px;
    background: $white;
    border-radius: 24px;
    border: none;
  }
  &__navigation {
    display: flex;
    position: absolute;
    padding: 0 26px;
    top: 50%;
    bottom: 50%;
    width: 100%;
    justify-content: space-between;
    height: max-content;
    transform: translateY(-50%);
    @media screen and ($desktop) {
      padding: 0 72px;
    }
    button {
      font-family: $font-cooper;
      height: 56px;
      width: 56px;
      border-radius: 28px;
      border: none;
      background: white;
      cursor: pointer;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover span {
        opacity: 1;
        pointer-events: unset;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: $white;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 1px;
        border-radius: 28px;
        padding: 17px 0;
        padding-left: 67px;
        padding-right: 22px;
        text-align: center;
        min-width: 177px;
        background: transparentize($color: #000, $amount: 0.75);
        opacity: 0;
        pointer-events: unset;
        transition: opacity ease-in-out 0.3s;
        display: none;
        @media screen and ($tablet) {
          display: block;
        }
      }
      &:last-child {
        span {
          left: unset;
          right: 1px;
          padding-right: 67px;
          padding-left: 22px;
        }
      }
    }
  }
}
