$blue: #3a80c1;
$soft-blue: #6897c3;
$softer-blue: #d8ecff;
$dark-blue: #2d3856;
$purple: #2d3856;
$white: #fff;
$soft-gray: #f6f6f6;
$font-gray: #707070;
$font-slate: #616A80;
$footer-font-gray: #c4c4c4;
$select-font-gray: #a1a1a1;
$red-brow: #d3757f;

$main-color: $blue;
$second-color: $white;
$third-color: $soft-blue;
$fourth-color: $purple;
$fifth-color: $soft-gray;
$sixth-color: $dark-blue;
$eight-color: $font-gray;
$ninth-color: $red-brow;
$tenth-color: $footer-font-gray;
$eleventh-color: $softer-blue;
$twelth-color: $select-font-gray;

$tablet: "min-width: 768px";
$desktop-small: "min-width: 912px";
$desktop: "min-width: 1024px";
$desktop-big: "min-width: 1240px";

$font-karla: "Karla", "Helvetica", Arial;
$font-cooper: "CooperLtBT", serif;
