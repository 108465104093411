@mixin keyframes(
  $name,
  $duration: 0.3s,
  $delay: 0,
  $list: (),
  $listMobile: ()
) {
  animation: $name $duration forwards;
  animation-delay: $delay;
  opacity: 0;
  @keyframes #{$name} {
    0% {
      @each $item in $list {
        #{nth($item, 1)}: #{nth($item, 2)};
      }
    }
    100% {
      @each $item in $list {
        #{nth($item, 1)}: #{nth($item, 3)};
      }
    }
  }
}

@mixin cta(
  $hovercolor: $ninth-color,
  $borderbottom: $second-color,
  $button-color: $main-color
) {
  font-family: $font-cooper;
  margin: 35px 0 auto;
  color: $button-color;
  line-height: 38px;
  font-size: 20px;
  border-bottom: solid 1px $borderbottom;
  svg {
    transition: 250ms ease-in-out all;
    path {
      fill: $button-color;
      transition: all 0.3s;
    }
  }
  &__arrow {
    margin: 36px 0 0;
  }
  &:hover {
    color: $hovercolor;
    border-bottom: 1px solid $hovercolor;
    svg {
      transition: 250ms ease-in-out all;
      transform: translateX(8px);
      path {
        fill: $hovercolor;
      }
    }
  }
}
