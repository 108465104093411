.disclaimer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background: transparentize($color: $blue, $amount: 0.5);
  padding: 164px 60px;
  transition: left ease-in-out .5s;
  &--gone{
    left: -100vw;
  }
  &__holder {
    position: relative;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
  &__box {
    @extend .wrap;
    max-height: 100%;
    overflow-y: scroll;
    left: 50%;
    position: absolute;
    top: 50%;
    background: $white;
    padding: 24px;
    height: max-content;
    transform: translate(-50%, -50%);
  }
  &__title,
  &__content,
  &__checkboxes {
    margin-bottom: 1em;
  }
  &__check-label {
    display: block;
    position: relative;
    &.checkbox-wrong {
      color: red;
      &::before {
        display: block;
        content: '!';
        position: absolute;
        background: red;
        color: $white;
        text-align: center;
        $s: 16px;
        left: (-4px - $s);
        width: $s;
        line-height: $s;
        height: $s;
        border-radius: 100%;
      }
    }
  }
  &__button {
    transition: all 0.5s;
    @include cta();
  }
}
