.faq {
  padding: 30px 20px;
  max-width: 1093px;
  width: 100%;
  margin: 80px auto;
  position: relative;
  &__wrap {
    background: $fifth-color;
    margin: 0 0 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &.left {
      .faq__answer {
        text-align: left;
      }
    }

    @media screen and ($tablet) {
      padding: 0;
    }
    &--open {
      .faq__question__title {
        color: $blue;
      }
      .faq__cross {
        transition: 400ms ease-in-out all;
        transform: rotate(135deg);
        transform-origin: 50% 50%;
        svg path {
          fill: $blue;
          transition: 400ms ease-in-out all;
        }
      }
    }
  }
  &__cross {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media screen and ($tablet) {
      position: absolute;
      margin-top: 0;
      right: 28px;
      top: 54px;
    }
    transition: 400ms ease-in-out all;
    svg {
      transform: rotate(0deg);
      display: block;
      margin: 0 auto;
      path {
        fill: $tenth-color;
        transition: 400ms ease-in-out all;
      }
    }
  }
  &__question {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and ($tablet) {
      min-height: 136px;
    }
    &__title {
      line-height: 36px;
      font-size: 18px;
      padding: 20px 30px;
      color: $eight-color;
      @media screen and ($tablet) {
        width: 100%;
      }
      &--open {
        color: $main-color;
      }
    }
  }

  &__answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 30px;
    line-height: 32px;
    font-size: 15px;
    color: $eight-color;
    transition: 300ms ease-in-out max-height;
    @media screen and ($tablet) {
      padding: 0 60px 0 30px;
      font-size: 18px;
      .answer-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 20px 40px 20px;
      }
    }
    &--open {
      transition: 300ms ease-in-out max-height;
      max-height: 9999999px;

      @media screen and ($tablet) {
        .answer-wrapper {
          max-height: 9000px;
        }
      }
    }
    p {
      margin: 0 0 10px;
    }
    a {
      transition: all 0.5s;
      color: $main-color;
      &:hover {
        color: $ninth-color;
      }
    }
  }
  .answer-wrapper {
    padding: 0 0 30px;
  }
}
