.quote {
  &__wrap {
    @media screen and ($desktop) {
      display: flex;
    }
  }
  &__layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: $main-color;
  }
  &__bg {
    transition: all 0.6s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    @media screen and ($tablet) {
      opacity: 1;
    }
  }
  &__comment {
    position: relative;
    background: $main-color;
    color: $second-color;
    padding: 60px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 50vh;
    @media screen and ($desktop) {
      width: 60%;
      padding: 100px 120px;
      text-align: left;
    }
    @media screen and ($desktop-big) {
      width: 55%;
      padding: 100px 120px;
      text-align: left;
    }
    &.scroll-state {
      .quote__bg {
        transform: translateY(-100px);
      }
      &.quote__comment--offscreen {
        &.show {
          .quote__bg {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &__title {
      position: relative;
      font-size: 30px;
      line-height: 52px;
      margin: auto 0 40px;
    }
    &__subtitle {
      position: relative;
      line-height: 38px;
      font-size: 20px;
      margin: 0 0 auto;
    }
  }
  &__text {
    padding: 80px 20px;
    background: $fifth-color;
    @media screen and ($desktop) {
      width: 40%;
      padding: 50px 60px;
    }
    @media screen and ($desktop-big) {
      width: 45%;
      padding: 100px 110px;
    }
    &__title {
      color: $main-color;
      margin: 0 0 40px;
      line-height: 44px;
      font-size: 26px;
    }
    &__content {
      color: $eight-color;
      font-size: 18px;
      line-height: 32px;
      @media screen and ($tablet) {
        font-size: 16px;
        line-height: 26px;
      }
      @media screen and ($desktop-big) {
        font-size: 18px;
        line-height: 32px;
      }
      a {
        transition: all 0.5s;
        color: $main-color;
        &:hover {
          color: $ninth-color;
        }
      }
    }
  }
}
