.work {
  &__intro {
    &__title {
      line-height: 38px;
      font-size: 20px;
      text-align: center;
      color: $main-color;
      margin: 60px 0 40px 0;
      @media screen and ($tablet) {
        margin: 115px 0 126px 0;
        line-height: 44px;
        font-size: 26px;
      }
    }
  }
  &__toggle {
    position: absolute;
    left: 20px;
    bottom: 20px;
    overflow: hidden;
    height: 60px;
    width: 60px;
    display: none;
    svg {
      position: absolute;
      left: -5px;
      top: 0;
    }
    .work__toggle-circle {
      transition: transform 0.5s;
      transform: rotate(0deg);
      transform-origin: 35px 31px;
    }
    &.rotate {
      .work__toggle-circle {
        transform: rotate(45deg);
      }
    }
    @media screen and ($tablet) {
      display: block;
      cursor: pointer;
    }
  }
  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 105px;
    max-width: 1155px;
    width: 100%;
    margin: 0 auto;
    &__layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
    }
    &__block {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 332px;
      padding: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @media screen and ($tablet) {
        width: 50%;
        height: 458px;
      }
      &__title {
        line-height: 44px;
        font-size: 26px;
        text-align: center;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        @media screen and ($tablet) {
          line-height: 52px;
          font-size: 30px;
        }
        &--text {
          position: relative;
          top: 0;
          opacity: 1;
          transition: all 0.6s;
          margin: auto;
        }
      }
      &__text {
        height: 0;
        opacity: 0;
        position: relative;
        top: 20px;
        overflow: hidden;
        text-align: center;
        line-height: 32px;
        font-size: 18px;
        transition: all 0.6s;
        font-size: 18px;
        line-height: 32px;
        @media screen and ($tablet) {
          font-size: 16px;
          line-height: 26px;
        }
        @media screen and ($desktop-big) {
          font-size: 18px;
          line-height: 32px;
        }
        a {
          text-decoration: underline;
        }
      }
      &.workHover {
        .work__grid__block__title--text {
          opacity: 0;
          top: 20px;
        }
        .work__grid__block__text {
          transition-delay: 0.6s;
          height: auto;
          opacity: 1;
          top: 0;
          margin: auto;
        }
      }
      &:nth-child(1) {
        color: $fourth-color;
        .work__grid__layer {
          background: linear-gradient(0deg, rgba(216, 236, 255, 0.9), rgba(216, 236, 255, 0.9));
        }
        a {
          color: $fourth-color;
        }
      }
      &:nth-child(2) {
        color: $second-color;
        .work__grid__layer {
          background-color: $main-color;
        }
        a {
          color: $second-color;
        }
      }
      &:nth-child(3) {
        color: $second-color;
        .work__grid__layer {
          background-color: $fourth-color;
        }
        a {
          color: $second-color;
        }
      }
      &:nth-child(4) {
        color: $fourth-color;
        .work__grid__layer {
          background: linear-gradient(0deg, rgba(216, 236, 255, 0.9), rgba(216, 236, 255, 0.9));
        }
        a {
          color: $fourth-color;
        }
      }
    }
  }
  &__button {
    width: 100%;
    display: block;
    text-align: center;
    padding: 100px 0 0 0;
    &__link {
      @include cta();
      border-bottom: solid 1px rgba(255, 255, 255, 0);
    }
    &.calltoaction {
      padding: 20px 0;
      margin-top: -12px;
    }
  }

  &__more-title {
    font-family: $font-cooper;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    padding-top: 18px;
    border-top: 1.5px solid #e5e5e5;
    color: #3b81c3;
    padding-bottom: 40px;
    @media screen and ($tablet) {
      padding-top: 36px;
      font-size: 20px;
      line-height: 38px;
    }
  }
}
