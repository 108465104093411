.form {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blue;
  position: relative;

  &__container {
    z-index: 1;
    min-width: 33%;

    // same as wrap-inside class
    margin: 120px auto 160px;
    max-width: calc(100% - #{30px * 2});

    @media screen and ($tablet) {
      margin: 160px auto;
      max-width: calc(100% - #{60px * 2});
    }
  }

  &__content {
    background: white;
    padding: 48px;

    @media screen and ($tablet) {
      width: calc(100vw - 120px);
      max-width: 984px;
      padding: 72px;
    }
  }

  &__icon {
    z-index: 0;
    bottom: 0;
    position: absolute;
  }

  &__message {
    display: grid;
    gap: 8px;
    padding-bottom: 8px;

    @media screen and ($tablet) {
      gap: 12px;
      padding-bottom: 12px;
    }
  }

  // all the form CMS-albe content

  &__title {
    color: $blue;
  }

  &__text {
    display: block;
    width: 100%;
    line-height: 1.5;
  }

  &__link {
    display: block;
    color: $blue;
    font-weight: bold;
    line-height: 1.5;
    padding: 4px 12px 4px 0;

    svg {
      transform: translateX(0px);
      transition: transform .2s ease-in-out;
    }

    &:hover {
      svg {
        transform: translateX(12px);
      }
    }
  }

  &__person {
    margin: 24px 0;
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 24px;

    @media screen and ($tablet) {
      grid-template-columns: 1fr 3fr;
    }
  }

  &__person-image {
    width: 100%;
    height: auto;
  }

}


.form-fields {
  display: grid;
  grid-gap: 24px;

  &__column {
    display: grid;
    grid-gap: 24px;

    &--double {
      @media screen and ($tablet) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &--flexible {
      @media screen and ($tablet) {
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: stretch;

        a {
          width: max-content;
          white-space: nowrap;
        }
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
      color: $blue;
      margin-bottom: 4px;
    }

    input {
      border: none;
      padding: 18px;
      border-bottom: solid 2px transparentize($dark-blue, .5);
      background-color: transparentize($dark-blue, .9);
      padding-left: 28px;

      &:focus {
        outline: none;
        border-bottom: solid 2px $blue;
        background-color: transparentize($blue, .8);

        &+svg {
          fill: $blue !important;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    width: 18px;
    left: 4px;
    bottom: calc(54px / 2);
    transform: translateY(50%);
    fill: transparentize($dark-blue, .5);
  }

  &__button {
    width: 100%;
    border: 2px solid $blue;
    background: transparent;
    color: $blue;
    display: block;
    padding: 20px 40px;
    font-size: 20px;
    font-family: "CooperLtBT", serif;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    cursor: pointer;

    &:active {
      background-color: transparentize($color: $blue, $amount: 0.5);
      color: $blue;
    }

    &:hover {
      background-color: $blue;
      color: white;
    }
  }
}