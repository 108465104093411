.works {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 0 5%;
  @media screen and ($desktop) {
    padding: 0 60px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  &__box {
    display: flex;
    overflow: hidden;
    height: 180px;
    background: $fifth-color;
    position: relative;
    cursor: pointer;
    margin: 0 0 40px;
    @media screen and ($desktop) {
      margin: 0 0 20px;
      height: 220px;
      width: calc(33.333% - 15px);
    }
    &:hover {
      .works__content {
        left: 0;
      }
      .works__link,
      .works__text {
        opacity: 1;
      }
      .works__link {
        transition-delay: 0.7s;
      }
    }
    &.hide {
      height: 0;
      padding: 0;
    }
  }
  &__title {
    color: $sixth-color;
    line-height: 38px;
    font-size: 20px;
    text-align: center;
    margin: auto;
    @media screen and ($desktop) {
      line-height: 44px;
      font-size: 26px;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: -100%;
    background: $main-color;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    transition: all 1s;
    overflow: hidden;

    &-image {
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      display: block;
      opacity: 0.1;
    }
  }
  &__link,
  &__text {
    color: $second-color;
  }
  &__link {
    opacity: 0;
    transition: all 0.5s;
    @include cta($second-color, $second-color, $main-color);
    color: $second-color;
    border: 0;
    font-size: 18px;
    margin: auto 0 0 0;
    line-height: 1;
    width: 150px;
    &:hover {
      border: 0;
    }
    svg {
      path {
        fill: $second-color;
      }
    }
  }
  &__text {
    transition: all 2s;
    line-height: 28px;
    font-size: 16px;
    opacity: 0;
  }
}
