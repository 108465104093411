.frontdoor {
  &__wrap {
    @media screen and ($desktop) {
      display: flex;
    }
  }
  &__reverse {
    @media screen and ($desktop) {
      flex-direction: row-reverse;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 5%;
    @media screen and ($tablet) {
      max-height: 712px;
      min-height: 48vw;
    }
    @media screen and ($desktop) {
      padding: 40px 5%;
    }
    @media screen and ($desktop-big) {
      padding: 80px 5%;
    }
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &.gray {
      background: $fifth-color;
    }
    &.small-gutter {
      min-height: 32vw;
    }
  }
  &__image {
    min-height: 100vw;
    min-width: 100vw;
    background-position: center;
    background-size: cover;
    @media screen and ($desktop) {
      min-width: 50%;
      min-height: auto;
    }
    &-inner {
      transform: scale(2);
    }
  }
  &__title {
    color: $main-color;
    line-height: 44px;
    font-size: 26px;
    margin: auto 0 32px;
    @media screen and ($desktop) {
      line-height: 52px;
      font-size: 30px;
      margin: auto 0 32px;
    }
    @media screen and ($desktop-big) {
      margin: auto 0 65px;
    }
  }
  &__content {
    margin: 0 0 auto;
    color: $eight-color;
    font-size: 18px;
    line-height: 32px;
    @media screen and ($tablet) {
      font-size: 16px;
      line-height: 26px;
    }
    @media screen and ($desktop-big) {
      font-size: 18px;
      line-height: 32px;
    }
    a {
      transition: all 0.5s;
      color: $main-color;
      &:hover {
        color: $ninth-color;
      }
    }
  }
  &__link {
    transition: all 0.5s;
    @include cta();
    border-bottom: solid 1px rgba(255, 255, 255, 0);
  }
}

// weird flex but okay

.frontdoorAnime {
  .frontdoor__image {
    position: relative;
    overflow: hidden;
    &-inner {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: -100px;
      background: {
        size: cover;
        position: 0 50%;
        color: $soft-blue;
      }
      backface-visibility: none;

      &:before {
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: $eleventh-color;
        display: block;
        position: absolute;
        z-index: 2;
        transition-property: width;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
      }
    }
  }
  &.show {
    .frontdoor__image {
      &-inner {
        transform: scale(1);
        &:before {
          width: 0;
          transition-property: width;
          transition-duration: 0.8s;
          transition-delay: 0.1s;
          transition-timing-function: ease-in-out;
        }
      }
    }
  }
}

.frontdoorAnime {
  .frontdoor__text {
    transition: opacity 0.5s;
    transition-delay: 0.5s;
    opacity: 0;
    position: relative;
  }
  .frontdoor__content,
  .frontdoor__title {
    opacity: 0;
    transition: opacity 0.6s;
    position: relative;
    top: -70px;
  }
  &.show {
    .frontdoor__text {
      opacity: 1;
      position: relative;
      top: 0;
    }
    .frontdoor__content,
    .frontdoor__title {
      opacity: 1;
      top: 0;
    }
  }
}
