.textimage {
  &__wrap {
    @media screen and ($desktop) {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &__image {
    background-size: cover;
    background-position: center;
    height: 285px;
    width: 100%;
    @media screen and ($desktop) {
      height: auto;
      width: 60%;
    }
    @media screen and ($desktop-big) {
      width: 55%;
    }
  }
  &__text {
    padding: 80px 20px;
    background: $fifth-color;
    @media screen and ($desktop) {
      width: 40%;
      padding: 50px 60px;
    }
    @media screen and ($desktop-big) {
      width: 45%;
      padding: 100px 110px;
    }
    &__title {
      color: $main-color;
      margin: 0 0 40px;
    }
    &__content {
      color: $eight-color;
      font-size: 18px;
      line-height: 32px;
      @media screen and ($tablet) {
        font-size: 16px;
        line-height: 26px;
      }
      @media screen and ($desktop-big) {
        font-size: 18px;
        line-height: 32px;
      }
      a {
        transition: all 0.5s;
        color: $main-color;
        &:hover {
          color: $ninth-color;
        }
      }
    }
  }
}
