.portefeuille {
  &__wrap {
    max-width: 640px;
    width: 100%;
    padding: 0 5%;
    margin: 0 auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    &__block {
      margin: 80px auto 80px auto;
    }
    @media screen and ($tablet) {
      padding: 0 30px;
      flex-direction: row;
    }
  }
  &__text {
    color: $eight-color;
    @media screen and ($tablet) {
      margin: 0 0 40px;
      width: calc(50% - 40px);
    }
    a {
      transition: all 0.5s;
      color: $main-color;
      &:hover {
        color: $ninth-color;
      }
    }
  }
  &__content {
    color: $eight-color;
    margin: 0 0 40px;
    max-width: 640px;
    margin: auto;
    text-align: center;
    @media screen and ($tablet) {
      //   p {
      //     column-count: 2;
      //     column-rule-width: 1px;
      //   }
    }
    a {
      transition: all 0.5s;
      color: $main-color;
      padding: 0 0 7px;
      display: inline-block;
      border-bottom: solid 1px $second-color;
      transition: all 0.5s;
      &:hover {
        color: $ninth-color;
        border-bottom: solid 1px $ninth-color;
      }
    }
  }
  &__link {
    color: $main-color;
    padding: 0 0 7px;
    margin: 40px 0 0 0;
    display: inline-block;
    border-bottom: solid 1px $second-color;
    transition: all 0.5s;
    &:hover {
      color: $ninth-color;
      border-bottom: solid 1px $ninth-color;
    }
  }
  &__title {
    line-height: 44px;
    font-size: 26px;
    color: $main-color;
    margin: 0 0 50px;
    width: 100%;
    &--big {
      font-size: 30px;
    }
  }
  &__information {
    background: $fifth-color;
    display: flex;
    margin: 90px 0 0 0;
    padding: 50px 0;
    @media screen and ($tablet) {
      padding: 100px 0;
    }
    .portefeuille__wrap {
      max-width: 866px;
    }
  }
  &__work {
    margin: 0 0 40px;
    @media screen and ($tablet) {
      width: calc(33.333% - 16px);

      &:nth-child(3n + 1) {
        margin-right: 16px;
      }
      &:nth-child(3n + 2) {
        margin-right: 8px;
        margin-left: 8px;
      }
      &:nth-child(3n + 3) {
        margin-left: 16px;
      }
    }
    &__title {
      color: $main-color;
    }
    &__subtitle {
      font-weight: normal;
      line-height: 32px;
      font-size: 18px;
      color: $eight-color;
    }
  }
  &__video {
    width: 100%;
    height: 328px;
  }
}
