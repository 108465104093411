
.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding-top: 95px;
  padding-bottom: 55px;
  @media screen and ($tablet) {
    margin: 0 -20px;
  }

  &__member {
    margin-bottom: 40px;
    flex: 1 0 100%;
    @media screen and ($tablet) {
      flex: 0 0 calc(50% - 40px);
      margin: 0 20px;
      margin-bottom: 62px;
    }

    @media screen and ($desktop) {
      flex: 0 0 calc(#{100% / 3} - 40px);
      margin-bottom: 96px;
    }

    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    opacity: 0;
    transform: translateY(-50px);

    &.always-visible {
      opacity: 1;
      transform: translateY(0);
    }

    &.show {
      opacity: 1;
      transform: translateY(0);
      /* show second image on scroll event instead of hover */
      .team__photo-overlay {
        &.scroll-state {
          transition: opacity 300ms ease-in-out;
          transition-delay: 1.5s;
          opacity: 1;
        }
        &.hover-state {
          transition: opacity 300ms ease-in-out;
          transition-delay: 0.3s;
        }
      }
    }
  }
  &__photo {
    width: 100%;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 120%;
    }
  }
  &__photo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center center no-repeat;
    background-size: cover;
    opacity: 0;
    will-change: opacity;
    transition: 250ms opacity ease-in-out;
    &.hover-state {
      .team__member:hover & {
        opacity: 1;
      }
    }
  }

  &__title {
    line-height: 42px;
    font-size: 20px;
    color: $main-color;
    margin: 8px 0 0 0;
    transition: color 0.5s;
    &:hover {
      color: $ninth-color;
    }
  }
  &__work {
    color: $eight-color;
  }
}
