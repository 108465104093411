.article {
  &__wrap {
    max-width: 866px;
    margin: 70px auto 0 auto;
    padding: 0 30px;
    @media screen and ($tablet) {
      padding: 0 65px;
    }
  }
  &__title,
  h2,
  h3,
  blockquote {
    color: $main-color;
    margin: 0 0 70px;
    line-height: 44px;
    font-size: 26px;
    font-family: CooperLtBT, serif;
    font-weight: 400;
    @media screen and ($tablet) {
      text-align: center;
      margin: 0 0 64px;
      line-height: 52px;
      font-size: 30px;
    }
  }
  &__subtitle,
  h3 {
    color: $main-color;
    line-height: 38px;
    font-size: 20px;
    margin: 0 0 40px;
    @media screen and ($tablet) {
      margin: 0;
      text-align: center;
    }
  }
  &__subtext {
    line-height: 32px;
    font-size: 15px;
    color: $eight-color;
    @media screen and ($tablet) {
      text-align: center;
      font-size: 18px;
    }
  }
  &__text,
  ul,
  p {
    line-height: 32px;
    font-size: 15px;
    color: $eight-color;
    margin: 0px 0 20px 0;
    @media screen and ($tablet) {
      font-size: 18px;
      text-align: center;
    }
    p {
      margin: 0 0 10px;
    }
    a {
      transition: all 0.5s;
      color: $main-color;
      &:hover {
        color: $ninth-color;
      }
    }
  }
  ul {
    text-align: left;
    li {
      margin-bottom: 0.5em;
    }
  }
  &__quote,
  blockquote {
    line-height: 52px;
    font-size: 25px;
    text-align: center;
    color: $sixth-color;
    margin: 0 0 30px;
    @media screen and ($tablet) {
      font-size: 30px;
    }
  }
  &__commenter {
    line-height: 32px;
    font-size: 18px;
    text-align: center;
    color: $sixth-color;
    margin: -20px 0 20px 0;
  }
}
