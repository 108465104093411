.hoverdoor-container {
  & + .hoverdoor-container {
    margin-top: -90px;
  }
}

.hoverdoor {
  padding-top: 60px;
  &__title {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    color: $main-color;
    line-height: 38px;
    font-size: 24px;
    transition: all 0.5s;
    border-bottom: 1px solid $second-color;
    &:hover {
      color: $ninth-color;
      border-bottom: 1px solid $ninth-color;
    }
    @media screen and ($desktop-big) {
      margin: 30px auto 70px auto;
      line-height: 52px;
      font-size: 30px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    > h3 {
      display: flex;
    }
    @media screen and ($tablet) {
      padding: 20px 20px;
    }
    @media screen and ($desktop) {
      padding: 20px 60px 90px;
    }
  }
  &__article {
    display: flex;
    max-width: 1240px;
    width: 100%;
    margin: 40px auto 0;
    flex-direction: column;

    &:hover {
      .hoverdoor__text {
        background: $main-color;
        color: $second-color;
        .hoverdoor__date {
          color: $eleventh-color;
        }
        .hoverdoor__subtitle {
          color: $second-color;
          &--headtitle {
            color: $sixth-color;
          }
        }
        .hoverdoor__link {
          color: $eleventh-color;
          svg {
            path {
              fill: $eleventh-color;
            }
          }
        }
      }
      .hoverdoor__image {
        &-img {
          transform: scale(1);
          transition: 300ms ease-in-out all;
        }
      }
      .hoverdoor__no-image {
        background-color: #3a80c1cf;
        transition: 300ms ease-in-out all;
        strong {
          transition: 300ms ease-in-out all;
          transform: scale(1.1);
        }
      }
    }

    @media screen and ($desktop) {
      padding: 0 20px;
      flex-direction: row;
    }

    &:nth-of-type(even) {
      @media screen and ($desktop) {
        flex-direction: row-reverse;
      }
    }
    &.reverse {
      @media screen and ($desktop) {
        flex-direction: row-reverse;
      }
      &:nth-of-type(even) {
        @media screen and ($desktop) {
          flex-direction: row;
        }
      }
    }
    &.show {
      .hoverdoor__image {
        &:before {
          width: 0;
          transition-property: width;
          transition-duration: 0.8s;
          transition-delay: 0.1s;
          transition-timing-function: ease-in-out;
        }
      }
    }
  }
  &__text,
  &__image {
    min-height: 74vw;
    width: 100%;
    @media screen and ($desktop) {
      min-height: auto;
      width: 50%;
    }
  }
  &__category {
    font-family: $font-karla;
    margin-bottom: 12px;
    display: block;
    font-size: 14px;
  }
  &__image {
    position: relative;
    overflow: hidden;
    display: flex;
    &-img {
      background-size: cover;
      background-position: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: scale(1.05);
      transition: 300ms ease-in-out all;
    }

    &:before {
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: $eleventh-color;
      display: block;
      position: absolute;
      z-index: 2;
      transition-property: width;
      transition-duration: 0.8s;
      transition-timing-function: ease-in-out;
    }
  }
  &__no-image {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $main-color;
    width: 100%;
    transition: 300ms ease-in-out all;

    strong {
      font-family: $font-cooper;
      color: white;
      font-size: 30px;
      transition: 300ms ease-in-out all;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    padding: 70px 5%;
    background: $fifth-color;
    transition: all 0.5s;
    @media screen and ($desktop) {
      padding: 70px;
    }
  }
  &__date {
    margin: auto auto 30px 0;
    line-height: 32px;
    font-size: 18px;
    color: $eight-color;
    @media screen and ($desktop) {
      margin: auto auto 30px 0;
    }
  }
  &__subtitle {
    margin: 0 auto auto 0;
    color: $main-color;
    line-height: 44px;
    font-size: 26px;
    margin: 0 auto 40px 0;
    @media screen and ($desktop) {
      margin: 0 auto 41px 0;
      line-height: 47px;
      font-size: 25px;
    }
    @media screen and ($desktop-big) {
      margin: 0 auto 89px 0;
      line-height: 52px;
      font-size: 30px;
    }
    &--headtitle {
      color: $main-color;
      line-height: 44px;
      font-size: 26px;
      color: $sixth-color;
      display: inline-block;
      width: 100%;
      @media screen and ($desktop) {
        line-height: 47px;
        font-size: 25px;
      }
      @media screen and ($desktop) {
        line-height: 52px;
        font-size: 30px;
      }
    }
  }

  &__link {
    @include cta($second-color, $fifth-color, black);
    border: 0;
    padding-bottom: 80px;
    &:hover {
      border: 0;
    }
    @media screen and ($desktop) {
      padding: 0;
    }
  }
  &__switch {
    padding-top: 80px;
    @media screen and ($desktop) {
      &:nth-child(even) {
        .wrap {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
