.location {
  &__wrap {
    @media screen and ($desktop) {
      display: flex;
    }
  }
  &__image {
    width: 100%;
    height: 332px;
    background-size: cover;
    background-position: center;
    position: relative;
    @media screen and ($desktop) {
      height: auto;
      width: 40%;
    }
    @media screen and ($desktop-big) {
      height: auto;
      width: 45%;
    }
  }
  &__animation {
    position: relative;
    text-align: right;
    background: #2d3855;
    display: flex;
    svg {
      display: block;
    }
    > div {
      margin: auto;
    }
    @media screen and ($desktop) {
      width: 40%;
    }
    @media screen and ($desktop-big) {
      width: 45%;
      justify-content: flex-end;
    }
  }
  &__info {
    padding: 30px;
    background: $fifth-color;
    @media screen and ($desktop) {
      min-height: 211px;
      width: 60%;
      padding: 41px 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media screen and ($desktop-big) {
      width: 55%;
      padding: 82px 0 82px 110px;
    }
    &__text {
      margin: 0 0 38px;
      @media screen and ($desktop) {
        padding: 0 20px 0 0;
        margin: 0 0 42px;
        width: 50%;
      }
      @media screen and ($desktop-big) {
        padding: 0 20px 0 0;
        margin: 0 0 42px;
      }
      &__title {
        color: $main-color;
        line-height: 38px;
        font-size: 20px;
      }
      &__subtitle {
        color: $eight-color;
        font-weight: normal;
        line-height: 32px;
        font-size: 18px;
        &--link {
          transition: all 0.5s;
          border-bottom: solid 1px rgba(255, 255, 255, 0);
          padding: 0 0 3px;
          &:hover {
            color: $ninth-color;
            border-bottom: solid 1px $ninth-color;
          }
        }
      }
    }
  }
  &__profile-image {
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
    &.show {
      opacity: 1;
    }
  }
}
