.footer {
  background: $fourth-color;
  color: $tenth-color;
  min-height: 335px;
  padding: 120px 20px;
  display: flex;
  @media screen and ($tablet) {
    padding: 0;
  }
  &__wrap {
    opacity: 0;
    position: relative;
    top: -20px;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    @media screen and ($tablet) {
      padding: 0 80px;
      max-width: 1240px;
      flex-direction: row;
      width: 100%;
    }
    &.show {
      opacity: 1;
      top: 0;
    }
  }
  &__logo {
    margin: auto;
    @media screen and ($tablet) {
      width: 43%;
      margin: auto auto auto 0;
      align-self: center;
    }
    &__mobile {
      margin: 0 0 60px;
      @media screen and ($tablet) {
        display: none;
      }
    }
    &__desktop {
      display: none;
      width: 100%;
      @media screen and ($tablet) {
        display: block;
      }
    }
  }
  &__contact {
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    @media screen and ($tablet) {
      width: 50%;
      display: flex;
      text-align: left;
      justify-content: space-between;
      align-self: center;
      padding-right: 140px;
    }
    a {
      color: $tenth-color;
      transition: color 0.5s;
      &:hover {
        color: #3b81c3;
      }
    }
    p {
      margin: 0 0 30px;
    }
    &__text {
      &:last-child {
        margin: 0;
      }
    }
  }
  &__language-switch {
    right: 0px;
    bottom: 70px;
    justify-content: center;
    margin-top: 30px;
    @media screen and ($tablet) {
      right: 40px;
      margin-top: 0;
      position: absolute;
    }

    @media screen and ($desktop-big) {
      right: 40px;
    }
  }

  &__link{
    display: block;
  }
}
