.entry-title {
  color: #3b81c3;
  font-family: $font-cooper;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 48px;
  @media screen and ($desktop-small) {
    line-height: 52px;
  }
}
.entry-content {
  color: $font-gray;
  font-family: $font-karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  @media screen and ($desktop-small) {
    font-size: 16px;
    line-height: 30px;
  }
}

.category-search {
  display: flex;
  flex-direction: column;
  width: 100%;

  .video-text__content {
    padding-right: 0;
    padding-left: 0;

    @media screen and ($desktop-small) {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  &__dropdown {
    margin-top: 12px;
    padding: 12px 32px;
    min-width: 156px;
    text-align: center;
    font-family: $font-cooper;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    border: 1px solid #3b81c3;
    background-color: #3b81c3;
    border-radius: 40px;
    color: $white;

    @media screen and ($desktop-small) {
      display: none;
    }

    svg {
      height: 12px;
      margin-left: 12px;
      transform: rotate(90deg);
    }

    &-buttons {
      display: none;
      top: 100%;
      width: 100%;
      z-index: 1;
      position: absolute;
      margin-top: 24px;
      background: white;
      padding: 16px 12px;
      border-radius: 12px;
      box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.25);

      &.open {
        display: block;
      }
    }

    &-button {
      display: block;
      padding: 6px 10px;
      border-radius: 8px;
      color: #3b81c3;
      line-height: 30px;

      &:hover,
      &:focus,
      &.active {
        background: #d8ecff;
      }
    }
  }

  &__tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 72px;
    margin-bottom: 90px;
    padding-top: 8px;
    align-content: center;
    border-top: 1.5px solid #e5e5e5;

    &-title {
      flex: 1;
      margin-top: 12px;
      font-size: 20px;
      display: flex;
      align-items: center;
      color: #3b81c3;
    }

    &-single {
      display: none;
      margin-left: 16px;
      margin-top: 12px;
      padding: 12px 32px;
      min-width: 156px;
      text-align: center;
      font-family: $font-cooper;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border: 1px solid #3b81c3;
      background-color: #3b81c3;
      border-radius: 40px;
      font-size: 20px;

      .category-search__link {
        color: $white;
      }

      &:not(.active) {
        background-color: $white;

        .category-search__link {
          color: #3b81c3;
          border: 0;
        }
      }

      @media screen and ($desktop-small) {
        display: flex;
      }
    }
  }

  &__container {
    max-width: 1440px - 40px * 2;
    width: 100%;
    margin: 0 auto;
    min-height: 400px;
    padding: 0 20px;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    max-width: 700px;
    width: calc(100% - 40px);
    align-items: center;
    font-family: $font-cooper;

    @media screen and ($desktop-small) {
      flex-direction: row;
      margin: 0 auto;
      width: 100%;
    }

    strong {
      color: $blue;
      padding-right: 12px;
      font-size: 20px;
      line-height: 38px;
      text-align: center;
      font-weight: lighter;
    }
    select::-ms-expand {
      display: none;
    }
    select {
      width: 100%;
      max-width: 300px;
      margin: 20px 0 0 0;
      -webkit-appearance: none;
      border-radius: 0;
      padding: 18px 46px 18px 26px;
      font-family: $font-cooper;
      border: 0;
      color: $font-gray;
      font-size: 16px;
      background: {
        color: $fifth-color;
        size: 12px;
        repeat: no-repeat;
        position: calc(100% - 20px) 50%;
        image: url("/assets/svg/select-arrow.svg");
      }
      @media screen and ($desktop-small) {
        flex: 1;
        margin: 0 0 0 20px;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }
  &__category {
    margin-bottom: 20px;
    color: #adadad;
    font-family: $font-karla;
    font-weight: lighter;
    @media screen and ($desktop-small) {
      margin-bottom: 40px;
    }
  }
  &__loadmore {
    display: flex;
    width: calc(100% - 40px);
    margin: 0 20px 40px 20px;
    padding: 20px;
    background-color: $soft-gray;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__no-result {
    padding: 80px 0 40px;
    text-align: center;
    color: $font-gray;
  }
  &__result {
    display: flex;
    margin: 40px 0;

    &.flex-column {
      flex-direction: column;
    }
    &.flex-row {
      flex-direction: row;
      flex-wrap: wrap;
      .category-search__entry {
        min-height: 200px;
        width: 100%;

        @media screen and ($desktop-small) {
          margin-bottom: 18px;
          max-width: calc((100% / 3) - 12px);
          &:nth-of-type(3n + 1) {
            margin-right: 12px;
          }
          &:nth-of-type(3n + 2) {
            margin-left: 6px;
            margin-right: 6px;
          }
          &:nth-of-type(3n + 3) {
            margin-left: 12px;
          }
        }
      }
    }
  }
  &__entry {
    margin-bottom: 30px;
    width: 100%;
    background-color: $soft-gray;
    display: flex;
    flex-direction: column;
    transition: 200ms ease-in-out all;
    font-family: $font-cooper;

    &:not(.no-hover):hover {
      transition: 200ms ease-in-out all;
      background-color: $blue;
      .entry-description {
        color: white;
      }
      &__category {
        margin-bottom: 40px;
        color: #adadad;
        font-family: $font-karla;
      }
    }

    .category-search__title {
      font-size: 26px;
      margin-bottom: 20px;
      font-weight: 300;
    }

    // portefeuille entry style

    &--portefeuille {
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .entry-description {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $font-karla;
        font-size: 15px;
        line-height: 1.5em;
        position: absolute;
        top: 0;
        left: -100%;
        bottom: 0;
        color: white;
        background: $blue;
        transition: all 1s;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: {
          size: cover;
        }
        &__text {
          transition: all 2s;
          opacity: 0;
        }
        &-readmore {
          display: block;
          margin: auto;
          font-weight: 300;
          font-size: 17px;
          opacity: 0;
          transition: all 2s;
          font-family: $font-cooper;
          color: $second-color;
          border: 0;
          font-size: 18px;
          position: relative;
          z-index: 1;
          &-text {
            display: inline-block;
            text-align: center;
            padding: 0 0 10px 0;
            border-bottom: solid 1px $second-color;
          }
          &:hover {
            border: 0;
          }
          svg {
            path {
              fill: $second-color;
            }
          }
        }
      }
      &:hover {
        background-color: $fifth-color;
        .entry-description {
          left: 0;
          transition: all 1s;
          &-readmore,
          &__text {
            opacity: 1;
          }
        }
      }
    }

    // client entry style

    &--client {
      align-items: stretch;
      flex-direction: column-reverse;

      @media screen and ($desktop-small) {
        flex-direction: row;
        &:nth-of-type(even) {
          flex-direction: row-reverse;
        }
      }
      .category-search__entry-vid {
        flex: 1;
        max-width: 100%;
        position: relative;
        overflow: hidden;
      }
      .category-search__entry-img {
        flex: 1;
        max-width: 100%;
        position: relative;
        overflow: hidden;
        min-height: 50vw;
        display: flex;

        @media screen and ($desktop-small) {
          min-height: 350px;
          max-width: 50%;
        }

        .entry-img-wrapper {
          transition: 200ms ease-in-out transform;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-size: cover;
          transform: scale(1.05);
        }
        .entry-no-image {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: $main-color;
          width: 100%;
          transition: 300ms ease-in-out all;

          strong {
            font-size: 30px;
            transition: 300ms ease-in-out all;
          }
        }
      }
      .category-search__entry-meta {
        box-sizing: border-box;
        flex: 1;
        max-width: 100%;
        padding: 30px 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 74vw;
        width: 100%;
        @media screen and ($tablet) {
          min-height: 52vw;
        }

        &.more-padding {
          padding-left: 32px;
          padding-right: 32px;
          padding-top: 42px;
          padding-bottom: 64px;
          max-width: 682px;
          margin: 0 auto;

          @media screen and ($desktop-small) {
            padding: 0 calc(18% - 126px);
          }
          @media screen and (min-width: 1440px) {
            padding: 0 112px;
          }
        }

        @media screen and ($desktop-small) {
          max-width: 50%;
          height: 32vw;
          min-height: 460px;
          max-height: 460px;
          padding: 70px;
        }
      }
      .entry-description {
        color: $blue;
        line-height: 38px;
        font-size: 20px;
        font-style: normal;
      }
      &:hover {
        .category-search__entry-img {
          .entry-img-wrapper {
            transition: 200ms ease-in-out transform;
            transform: scale(1);
          }
        }
        .category-search__category {
          transition: 300ms ease-in-out all;
          color: white;
        }
        .entry-no-image {
          background-color: #5c9ad3;
          transition: 300ms ease-in-out all;
          strong {
            transition: 300ms ease-in-out all;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  &__port-image {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.1;
    height: 100%;
    min-width: 100%;
  }
}

.category-search__pastcurrent {
  display: flex;
  width: 100%;
  margin: 0 0 40px;
}

.category-search__pastcurrent__wrapper {
  position: relative;
  width: 100%;
  @media screen and ($desktop-small) {
    margin: 0 0 0 auto;
    width: auto;
  }
}

.category-search__pastcurrent__button {
  position: relative;
  font-size: 17px;
  border: none;
  display: inline-block;
  background: $eleventh-color;
  color: $sixth-color;
  padding: 15px 130px 15px 20px;
  border-radius: 25px;
  width: 100%;
  cursor: pointer;
  transition: color 0.5s;
  @media screen and ($desktop-small) {
    width: auto;
    font-size: 19px;
  }
  &:hover {
    color: $main-color;
    .category-search__pastcurrent__arrow {
      fill: $main-color;
    }
  }
  &:focus {
    outline: none;
  }
  .category-search__pastcurrent__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    right: 20px;
    fill: $sixth-color;
    transition: fill 0.5s;
  }
}

.category-search__pastcurrent__menu {
  font-size: 13px;
  background: $second-color;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.28);
  width: 100%;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 60px;
  z-index: -1;
  opacity: 0;
  transition: all 0.2s;
  padding: 10px;
  &.show {
    z-index: 3;
    opacity: 1;
    top: 70px;
  }
}

.category-search__pastcurrent__layer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  &.active {
    display: block;
  }
}

.category-search__pastcurrent__list {
  list-style: none;
  margin: 0;
  padding: 0;
  &.reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .category-search__pastcurrent__listitem {
    border-radius: 3px;
    padding: 3px 10px;
    margin: 0 0 5px;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      background: $eleventh-color;
    }
    a {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.category-search__pastcurrent__point {
  background: $second-color;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 25px;
  top: -7px;
  transform: rotate(-135deg);
  box-shadow: 4px 3px 5px -3px rgba(0, 0, 0, 0.28);
}

.category-search__pastcurrent__hoverdoorwrap {
  margin: auto;
  max-width: 1240px;
  padding: 20px;
  @media screen and ($desktop) {
    padding: 0 20px;
  }
}
