.partner {
  &__wrap {
    display: flex;
    flex-direction: column;
    @media screen and ($desktop-big) {
      flex-direction: row;
    }
  }
  &__text,
  &__contact {
    @media screen and ($desktop-big) {
      width: 50%;
    }
  }
  &__text {
    background: $fifth-color;
    padding: 80px 32px;
    line-height: 29px;
    font-size: 15px;
    color: $eight-color;
    @media screen and ($desktop) {
      padding: 72px 130px;
      font-size: 18px;
    }
    a {
      transition: all 0.5s;
      color: $main-color;
      &:hover {
        color: $ninth-color;
      }
    }
  }
  &__contact {
    background: $eleventh-color;
    display: flex;
    flex-direction: column;
    padding: 48px 34px;
    @media screen and ($desktop) {
      padding: 105px 80px;
      flex-direction: row;
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin: 0 0 31px;
      @media screen and ($desktop) {
        padding: 15px;
        margin: 0 auto;
      }
      &__title {
        margin: auto auto 0 0;
        color: $main-color;
        line-height: 38px;
        font-size: 20px;
      }
      &__subtitle {
        margin: 0 auto auto 0;
        line-height: 32px;
        font-size: 18px;
        font-weight: normal;
        color: $eight-color;
        &.link {
          transition: color 0.5s;
          &:hover {
            color: $ninth-color;
          }
        }
      }
    }
  }
  &__interview {
    &__title {
      font-size: 18px;
      line-height: 32px;
      font-weight: lighter;
      text-align: center;
      color: $fourth-color;
      margin: 60px 0 32px 0;
      @media screen and ($tablet) {
        margin: 120px 0 32px 0;
      }
    }
    &__subtitle {
      font-size: 25px;
      line-height: 30px;
      color: $main-color;
      text-align: center;
      margin: auto auto 32px;
      @media screen and ($tablet) {
        font-size: 30px;
        line-height: 52px;
      }
    }
    &__text {
      color: $eight-color;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      p {
        padding: 0 0 35px;
      }
      a {
        transition: all 0.5s;
        color: $main-color;
        &:hover {
          color: $ninth-color;
        }
      }
    }
    &__box {
      padding: 0 20px;
      max-width: 638px;
      margin: auto auto 40px;
      border-bottom: solid 1px $fifth-color;
      &:last-child {
        margin: auto auto -40px;
      }
      @media screen and ($tablet) {
        padding: 0;
        margin: auto auto 80px;
      }
    }
  }
  &__footer {
    background: $main-color;
    padding: 20px 30px;
    text-align: center;
    margin: 15px 0 -120px;
    transition: all 0.5s;
    &:hover {
      background: $third-color;
    }
    &__link {
      color: $second-color;
      line-height: 38px;
      font-size: 20px;
    }
  }
}
.project {
  padding: 80px 32px 50px 32px;
  @media screen and ($desktop) {
    padding: 104px 0 50px;
  }
  &__title {
    text-align: center;
    color: $main-color;
    line-height: 38px;
    font-size: 20px;
  }
}
