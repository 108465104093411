.lang-switch {
  display: flex;
  font-family: $font-karla;
  color: #c4c4c4;
  &__link {
    margin: 0 6px;
    font-weight: bold;
    color: #c4c4c4;
    &.active-language {
      color: white;
      border-bottom: 2px solid white;
    }
    &:hover {
      color: white;
    }
    &--active {
      color: white;
      border-bottom: 2px solid #fff;
    }
  }
}
