
.scale-enter-active {
  transition: 500ms all ease-in-out;
  max-height: 500px;
  padding: 30px 60px;
}

.scale-enter-active {
  transition-delay: 0s;
}

.scale-enter,
.scale-leave-active {
  max-height: 0;
  padding: 0;
}
