.backbutton {
	background: $main-color;
	transition: all 0.5s;
	&__link {
		color: #fff;
		line-height: 38px;
		font-size: 20px;
		text-align: center;
		display: block;
		padding: 35px 20px;
		&:hover {
			color: #2d3856;
		}
	}
	&:hover {
		background: #d8ecff;
	}
}
