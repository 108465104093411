.link-cards {
  width: calc(100% + 40px);
  display: flex;
  margin: 0 -20px;
  flex-direction: column;
  @media screen and ($tablet) {
    flex-direction: row;
  }
  &__card {
    flex: 1;
    margin: 20px;
    position: relative;
    flex-wrap: wrap;
  }
  &__background {
    pointer-events: none;
    z-index: 1;
    position: absolute;
    width: 100%;
    opacity: 0.2;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__link {
    display: block;
    width: 100%;
    color: $dark-blue;
    position: relative;
    padding-bottom: 100% / 311 * 375;
    transition: background-image ease-in-out 0.2s;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: #d8ecff;
      transition: background 0.2s ease-in-out;
    }

    // When on the Team.twig page
    &.more {
      @media screen and ($tablet) {
        padding-bottom: 100% / 413 * 496;
      }
    }
    &:hover,
    &:focus {
      color: white;
      .background {
        background: #3b81c3;
      }
      svg path {
        fill: white;
      }
    }

    @media screen and ($tablet) {
      padding-bottom: 100% / 16 * 9;
    }
  }
  &__link-text {
    z-index: 3;
    font-family: $font-cooper;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    @media screen and ($tablet) {
      font-size: 26px;
    }
    @media screen and ($desktop) {
      font-size: 30px;
    }
  }
  &__link-arrow {
    z-index: 3;
    font-family: $font-cooper;
    right: 52px;
    bottom: 32px;
    position: absolute;
    &:hover,
    &:focus {
      svg {
        transform: translate(12px);
      }
    }
    svg {
      margin-left: 12px;
      transition: transform 0.2s ease-in-out;
      height: 12px;
      path {
        fill: $dark-blue;
      }
    }
  }
}
