
.fade-enter-active {
  transition: 300ms all ease-in-out;
}

.fade-enter-active {
  transition-delay: 0s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
// styles located in category-filter.scss
